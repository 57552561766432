import { SharedModule } from 'apex-shared';
import { AppRoutingModule } from './app-routing.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

import { AppComponent } from './app.component';
import { DashboardModule } from '@crm-portal/modules/dashboard';
import { LayoutModule } from '@crm-portal/core/layout';
import { AuthModule } from '@crm-portal/core/auth/auth.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';

import {
    ConfigurationModule,
    LoggerModule,
    SupportedLanguages,
    TranslateModuleOptions,
    NotificationModule,
    TRANSLATE_MODULE_OPTIONS_CONFIG,
    initAppConfiguration,
    AppInitService,
    ErrorsHandler,
    CommonModule,
    TranslationModule,
    DictionariesModule,
    FcTokenModule
} from 'farmcloud-core';
import { Observable, from } from 'rxjs';
import { FormSharedModule } from 'farmcloud-shared';
import { UiSwitchModule } from 'ngx-ui-switch'
import { UserGuard } from './core/auth/user.guard';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    suppressScrollX: true,
    wheelPropagation: false
};

const DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG: TranslateModuleOptions = {
    defaultLanguage: SupportedLanguages.PL
};

export class WebpackTranslateLoader implements TranslateLoader {
    // tslint:disable-next-line: no-any
    getTranslation(lang: string): Observable<any> {
        return from(System.import(`../assets/i18n/${lang}.json`));
    }
}

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        PerfectScrollbarModule,
        LayoutModule,
        DashboardModule,
        SharedModule,
        HttpClientModule,
        NotificationModule,
        LoggerModule,
        FcTokenModule,
        AuthModule,
        ConfigurationModule,
        CommonModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: WebpackTranslateLoader
            }
        }),
        TranslationModule,
        FormSharedModule,
        UiSwitchModule,
        DictionariesModule
    ],
    providers: [
        UserGuard,
        // https://github.com/ng-packagr/ng-packagr/issues/727
        {
            provide: APP_INITIALIZER,
            useFactory: initAppConfiguration,
            deps: [AppInitService],
            multi: true
        },
        {
            provide: TRANSLATE_MODULE_OPTIONS_CONFIG,
            useValue: DEFAULT_TRANSLATE_MODULE_OPTIONS_CONFIG
        },
        {
            provide: PERFECT_SCROLLBAR_CONFIG,
            useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
        },
        {
            provide: ErrorHandler,
            useClass: ErrorsHandler,
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
