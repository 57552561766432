import {
    DynamicFormControlModel,
    DynamicFormGroupModel,
    DynamicInputModel,
    DynamicSelectModel
    } from '@ng-dynamic-forms/core';
import { EmployeePosition, CreateEntity } from 'farmcloud-core';
import { getOptionsForDynamicSelectModel } from 'farmcloud-shared';
import { ContactForm } from './contact-form.model';
import { CreateContact } from './create-contact.model';

export const FORM_MODEL: DynamicFormControlModel[] = [
    new DynamicFormGroupModel({
        id: 'contactBaseGroup',
        group: [
            new DynamicInputModel({
                id: 'name',
                minLength: 3,
                maxLength: 60,
                autoFocus: true,
                validators: {
                    required: null,
                    minLength: 3,
                    maxLength: 60
                }
            }),
            new DynamicInputModel({
                id: 'surname',
                minLength: 3,
                maxLength: 60,
                validators: {
                    required: null,
                    minLength: 3,
                    maxLength: 60
                }
            }),
            new DynamicInputModel({
                id: 'phoneNumber',
                labelTooltip: 'CONTACT.phoneNumber:label',
                validators: {
                    pattern: /^((\+|00)?48)?\d{9}$/
                }
            }),
            new DynamicInputModel({
                id: 'email',
                validators: {
                    email: null
                }
            }),
            new DynamicSelectModel({
                id: 'employeePosition',
                options: getOptionsForDynamicSelectModel(EmployeePosition, 'EMPLOYEE_POSITION', true)
            })
        ]
    })
];

export function mapToContactForm(data: CreateContact): ContactForm {
    return {
        contactBaseGroup: {
            ...data,
            ...{ employeePosition: data.employeePosition || '' }
        }
    }
}

export function mapFromContactForm(data: ContactForm): CreateEntity<CreateContact> {
    return {
        ...data.contactBaseGroup
    };
}
