import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class OrganizationContextService {

    private organizationIdContext: BehaviorSubject<string> = new BehaviorSubject(null);

    public organizationIdContext$;

    constructor() {
        this.organizationIdContext$ = this.organizationIdContext.asObservable();
    }

    get organizationId(): string | undefined {
        return this.organizationIdContext.value;
    }

    public clean() {
        this.organizationIdContext.next(null);
    }

    public setOrganizationId(organizationId: string) {
        this.organizationIdContext.next(organizationId);
    }
}
