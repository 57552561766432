import {
    DynamicFormControlModel,
    DynamicFormGroupModel,
    DynamicInputModel
    } from '@ng-dynamic-forms/core';
import { CreateEntity, nameof } from 'farmcloud-core';
import { VehTelDeviceForm } from './vehtel-device-form.model';
import { CreateVehTelDevice } from './create-vehtel-device.model';
import {
    DynamicMultiSelectControlModel
} from 'farmcloud-shared';
import { VehTelListMachineItem } from './vehtel-list-machine-item.model';
import { MultiSelectControl } from './multiSelectControl.model';

export const FORM_MODEL: DynamicFormControlModel[] = [
    new DynamicFormGroupModel({
        id: 'vehTelDeviceBaseGroup',
        group: [
            new DynamicInputModel({
                id: 'imei',
                minLength: 2,
                maxLength: 15,
                inputType: 'number',
                autoFocus: true,
                validators: {
                    required: null,
                    minLength: 2,
                    maxLength: 60
                }
            }),
            new DynamicMultiSelectControlModel({
                id: 'vehicle',
                bindLabel: nameof<VehTelListMachineItem>('name'),
                groupBy: nameof<VehTelListMachineItem>('organizationName'),
                multiple: false,
                // tslint:disable-next-line: no-duplicate-string
                translationPrefix: '',
                searchByField: nameof<VehTelListMachineItem>('name')
            }),
            new DynamicMultiSelectControlModel({
                id: 'typeOfDevice',
                bindLabel: nameof<MultiSelectControl>('label'),
                multiple: false,
                // tslint:disable-next-line: no-duplicate-string
                translationPrefix: '',
                searchByField: nameof<MultiSelectControl>('label'),
            }),
            new DynamicMultiSelectControlModel({
                id: 'devicesExtra',
                bindLabel: nameof<MultiSelectControl>('label'),
                multiple: true,
                // tslint:disable-next-line: no-duplicate-string
                translationPrefix: '',
                searchByField: nameof<MultiSelectControl>('label'),
            }),
        ]
    }),
];

export function mapToVehTelDeviceForm(data: CreateVehTelDevice): VehTelDeviceForm {
    return {
        vehTelDeviceBaseGroup: {
            ...data
        }
    }
}

export function mapFromVehTelDeviceForm(data: VehTelDeviceForm): CreateEntity<CreateVehTelDevice> {
    if(data.vehTelDeviceBaseGroup.vehicle){
        data.vehTelDeviceBaseGroup.vehicleRefId = data.vehTelDeviceBaseGroup.vehicle.id;
        data.vehTelDeviceBaseGroup.organizationId = data.vehTelDeviceBaseGroup.vehicle.organizationId;
    }
    return {
        vehicleRefId: data.vehTelDeviceBaseGroup.vehicleRefId,
        imei: data.vehTelDeviceBaseGroup.imei,
        deviceType: (data.vehTelDeviceBaseGroup.typeOfDevice)? data.vehTelDeviceBaseGroup.typeOfDevice.value : undefined,
        extraDevices: (data.vehTelDeviceBaseGroup.devicesExtra)? data.vehTelDeviceBaseGroup.devicesExtra.map(x => {
            return x.value
        }) : undefined,
        vehicle: null,
        organizationId: data.vehTelDeviceBaseGroup.organizationId,
        typeOfDevice: null,
        devicesExtra: null,
        permissions: []
    };
}
