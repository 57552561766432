import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { RequestList, ApiListResponse, DeviceType, NotificationService } from 'farmcloud-core';
import { WeatherStationCoreServicesModule } from '../weather-station-core-services.module';
import { WeatherStationCoreProxyService } from './weather-station-core-proxy.service';
import { IWeatherStationListFilter } from '../models/weather-station-list-filter';
import { IWeatherStationListItemDto } from '../models/weather-station-list-item.dto';
import { ICreateWeatherStationCommand, IUpdateWeatherStationCommand } from '../models/weather-station-command';
import { IWeatherStationDto } from '../models/weather-station.dto';

@Injectable({
    providedIn: WeatherStationCoreServicesModule
})
export class WeatherStationCoreService {

    constructor(
        private weatherStationCoreProxyService: WeatherStationCoreProxyService,
        private notificationService: NotificationService) { }

    getAll(deviceType: DeviceType): Observable<IWeatherStationListItemDto[]> {
        const requestList = {
            filter: {} as IWeatherStationListFilter
        } as RequestList<IWeatherStationListFilter>;
        return this.find(deviceType, requestList).pipe(map(result => result.items));
    }

    find(
        deviceType: DeviceType,
        requestList: RequestList<IWeatherStationListFilter>): Observable<ApiListResponse<IWeatherStationListItemDto>> {
        return this.weatherStationCoreProxyService.find(deviceType, requestList);
    }

    get(deviceType: DeviceType, id: string): Observable<IWeatherStationDto> {
        return this.weatherStationCoreProxyService.get(deviceType, id);
    }

    create(deviceType: DeviceType, model: ICreateWeatherStationCommand): Observable<string> {
        model.id = uuid();
        return this.weatherStationCoreProxyService.create(deviceType, model).pipe(
            tap(() => this.notificationService.success(`WEATHERSTATION.${deviceType}.create:success`)),
            catchError(error => {
                this.notificationService.error(error, `WEATHERSTATION.${deviceType}.create:errorMessage`);
                return of(null);
            })
        );
    }

    update(deviceType: DeviceType, id: string, model: IUpdateWeatherStationCommand): Observable<boolean> {
        return this.weatherStationCoreProxyService.update(deviceType, id, model).pipe(
            tap(() => this.notificationService.success(`WEATHERSTATION.${deviceType}.update:success`)),
            map(_ => true),
            catchError(error => {
                this.notificationService.error(error, `WEATHERSTATION.${deviceType}.update:errorMessage`);
                return of(false);
            })
        );
    }

    delete(deviceType: DeviceType, id: string): Observable<boolean> {
        return this.weatherStationCoreProxyService.delete(deviceType, id).pipe(
            tap(() => this.notificationService.success(`WEATHERSTATION.${deviceType}.delete:success`)),
            map(_ => true),
            catchError(error => {
                this.notificationService.error(error, `WEATHERSTATION.${deviceType}.delete:errorMessage`);
                return of(false);
            })
        );
    }
}
