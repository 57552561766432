import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiListResponse,
    AppConfigurationService,
    CreateEntity,
    prepareListUrlWithFilter,
    RequestList
    } from 'farmcloud-core';
import { Observable } from 'rxjs';
import { ContactListFilter } from './contact-list-filter.model';
import { ContactListItem } from './contact-list-item.model';
import { CreateContact } from './create-contact.model';

@Injectable({
    providedIn: 'root'
})
export class ContactProxyService {

    private readonly apiUrl: string;

    constructor(private httpClient: HttpClient, private config: AppConfigurationService) {
        this.apiUrl = `${this.config.configuration.api_url}/v1/contacts`;
    }

    getList(requestList: RequestList<ContactListFilter>): Observable<ApiListResponse<ContactListItem>> {
        return this.httpClient.get<ApiListResponse<ContactListItem>>(
            prepareListUrlWithFilter(this.apiUrl, requestList, this.prepareFilterUrl));
    }

    create(model: CreateEntity<CreateContact>): Observable<string> {
        return this.httpClient.post<string>(`${this.apiUrl}`, model);
    }

    get(id: string): Observable<CreateContact[]> {
        return this.httpClient.get<CreateContact[]>(`${this.apiUrl}/${id}`);
    }

    delete(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${id}`);
    }

    update(id: string, model: CreateContact): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/${id}`, model);
    }

    private prepareFilterUrl(filter: ContactListFilter) {
        const params: string[] = [];
        if (filter.name != null && filter.name !== '') {
            params.push(`Name=${filter.name}`);
        }

        if (filter.surname != null && filter.surname !== '') {
            params.push(`Surname=${filter.surname}`);
        }

        return params;
    }
}
