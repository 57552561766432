import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslationModule, SpinnerModule } from 'farmcloud-core';
import { SharedModule } from 'apex-shared';
import { LayoutSharedModule } from 'farmcloud-shared';
import { FullLayoutComponent } from './full-layout/full-layout.component';
import { LoadUserDataComponent } from './load-user-data/load-user-data.component';
import { UserNavItemComponent } from './navbar/user-nav-item/user-nav-item.component';
import { MessageCoreServicesModule } from '../message';

@NgModule({
    declarations: [
        FullLayoutComponent,
        LoadUserDataComponent,
        UserNavItemComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        // NgbModule,
        SpinnerModule,
        RouterModule,
        TranslationModule,
        LayoutSharedModule,
        MessageCoreServicesModule
    ],
    exports: [FullLayoutComponent]
})
export class LayoutModule {}
