import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalModule, MsalService, MsalGuard, MsalInterceptor } from '@azure/msal-angular';
import { WINDOW, AppConfigurationService } from 'farmcloud-core';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        MsalModule
    ],
    providers: [
        MsalGuard,
        MsalService,
        {
            provide: MSAL_CONFIG,
            useFactory: (window, appConfigurationService: AppConfigurationService) => ({
                auth: {
                    clientId: appConfigurationService.configuration.ad_clientID,
                    authority: appConfigurationService.configuration.ad_authority,
                    validateAuthority: true,
                    redirectUri: window.location.origin + '/',
                    navigateToLoginRequestUrl: true
                }
            }),
            deps: [WINDOW, AppConfigurationService]
        },
        {
            provide: MSAL_CONFIG_ANGULAR,
            useFactory: (appConfigurationService: AppConfigurationService) => ({
                consentScopes: [
                    appConfigurationService.configuration.ad_clientID,
                    ],
                popUp: false,
                protectedResourceMap: [
                    [appConfigurationService.configuration.api_url, [appConfigurationService.configuration.ad_clientID]]
                ]
            }),
            deps: [AppConfigurationService]
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        }
    ],
    exports: [
        MsalModule
    ]
})
export class AuthModule { }
