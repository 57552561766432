import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {  map, catchError, finalize } from 'rxjs/operators';
import { NotificationService } from 'farmcloud-core';
import { MessageCoreServicesModule } from '../message-core-services.module';
import { CreateMessageCommand } from '../models/message-command.model';
import { MessageCoreProxyService } from './message-core-proxy.service';
import { MessageCoreNotificationService } from './message-core-notification.service';
import { IMessageDto } from '../models/message.dto';

@Injectable({
    providedIn: MessageCoreServicesModule
})
export class MessageCoreService {

    constructor(
        private notificationService: NotificationService,
        private messageCoreProxyService: MessageCoreProxyService,
        private messageNotificationCoreService: MessageCoreNotificationService) {
    }

    public getAll(): Observable<IMessageDto[]> {
        return this.messageCoreProxyService.getAll();
    }

    public createMessage(messageCommand: CreateMessageCommand): Observable<boolean> {
        return this.messageCoreProxyService.createMessage(messageCommand)
            .pipe(
                map(() => {
                    this.notificationService.success('ORGANIZATION.MESSAGE.create:success');
                    return true;
                }),
                catchError(error => {
                    this.notificationService.error(error, 'ORGANIZATION.MESSAGE.create:errorMessage');
                    return of(false);
                })
            );
    }

    public markAsRead(id: string): Observable<boolean> {
        return this.messageCoreProxyService.markAsRead(id)
            .pipe(
                map(() => true),
                catchError(error => {
                    this.notificationService.error(error, 'MESSAGE.markAsRead:error');
                    return of(false)
                }),
                finalize(() => {
                    this.messageNotificationCoreService.refresh();
                })
            )
    }

}
