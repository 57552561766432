import { DeviceType } from 'farmcloud-core';
import { IWeatherStationListItemDto } from '@crm-portal/core/weather-station';

export class WeatherStation implements IWeatherStationListItemDto {

    id: string;
    serialNumber: string;
    status: string;
    createdAt: Date;
    registrationDate: Date;
    lastActiveDate: Date;
    organizationId: string;
    organizationName: string;
    location: Array<number>;
    isDeleted: boolean;

    deviceType: DeviceType;

    constructor(dto: IWeatherStationListItemDto, deviceType: DeviceType) {
        this.id = dto.id;
        this.serialNumber = dto.serialNumber;
        this.status = dto.status;
        this.createdAt = dto.createdAt;
        this.registrationDate = dto.registrationDate;
        this.lastActiveDate = dto.lastActiveDate;
        this.organizationId = dto.organizationId;
        this.organizationName = dto.organizationName;
        this.location = dto.location;
        this.isDeleted = dto.isDeleted;

        this.deviceType = deviceType;
    }
}
