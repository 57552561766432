import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ApiListResponse, RequestList, AppConfigurationService, prepareListUrlWithFilter, DeviceType } from 'farmcloud-core';
import { IWeatherStationListFilter } from '../models/weather-station-list-filter';
import { IWeatherStationListItemDto } from '../models/weather-station-list-item.dto';
import { IUpdateWeatherStationCommand, ICreateWeatherStationCommand } from '../models/weather-station-command';
import { IWeatherStationDto } from '../models/weather-station.dto';
import { IWeatherStationPermissionDto } from '../models/weather-station-permission.dto';
import { IUpdateWeatherStationPermissionCommand } from '../models/weather-station-permission-command';

@Injectable({
    providedIn: 'root'
})
export class WeatherStationCoreProxyService {

    private readonly readApiUrl: string;
    private readonly writeApiUrl: string;

    constructor(private httpClient: HttpClient, private config: AppConfigurationService) {
        this.readApiUrl = `${this.config.configuration.api_url}/v1/sensors`;
        this.writeApiUrl = `${this.config.configuration.api_url}/v1/weatherstations/sensors`;
    }

    find(
        deviceType: DeviceType,
        requestList: RequestList<IWeatherStationListFilter>): Observable<ApiListResponse<IWeatherStationListItemDto>> {
        return this.httpClient.get<ApiListResponse<IWeatherStationListItemDto>>(
            prepareListUrlWithFilter(this.createReadApiUrl(deviceType), requestList, this.prepareFilterUrl));
    }

    get(deviceType: DeviceType, id: string): Observable<IWeatherStationDto> {
        return this.httpClient.get<IWeatherStationDto>(`${this.createReadApiUrl(deviceType)}/${id}`);
    };

    create(deviceType: DeviceType, model: ICreateWeatherStationCommand): Observable<string> {
        return this.httpClient.post<string>(this.createWriteApiUrl(deviceType), model);
    }

    update(deviceType: DeviceType, id: string, model: IUpdateWeatherStationCommand): Observable<void> {
        return this.httpClient.put<void>(`${this.createWriteApiUrl(deviceType)}/${id}`, model);
    }

    delete(deviceType: DeviceType, id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.createWriteApiUrl(deviceType)}/${id}`);
    }

    getPermissions(deviceType: DeviceType, id: string): Observable<IWeatherStationPermissionDto[]> {
        return this.httpClient.get<IWeatherStationPermissionDto[]>(`${this.createWriteApiUrl(deviceType)}/${id}/permissions`);
    }

    updatePermissions(deviceType: DeviceType, id: string, data: IUpdateWeatherStationPermissionCommand[]): Observable<void> {
        return this.httpClient.put<void>(`${this.createWriteApiUrl(deviceType)}/${id}/permissions`, data);
    }

    private prepareFilterUrl(filter: IWeatherStationListFilter) {
        const params: string[] = [];
        if (filter.serialNumber != null && filter.serialNumber !== '') {
            params.push(`SerialNumber=${filter.serialNumber}`);
        }

        return params;
    }

    private createReadApiUrl(deviceType) {
        return `${this.readApiUrl}/${deviceType}`;
    }

    private createWriteApiUrl(deviceType) {
        return `${this.writeApiUrl}/${deviceType}`;
    }

}
