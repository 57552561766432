import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiListResponse, RequestList } from 'farmcloud-core';
import { ContactProxyService } from './contact-proxy.service';
import { ContactListFilter } from './contact-list-filter.model';
import { ContactListItem } from './contact-list-item.model';

@Injectable({
  providedIn: 'root'
})
export class ContactListService {

    constructor(private contactProxyService: ContactProxyService) { }

    getList(requestList: RequestList<ContactListFilter>): Observable<ApiListResponse<ContactListItem>> {
        return this.contactProxyService.getList(requestList);
    }
}
