import { NotificationService, CreateEntity } from 'farmcloud-core';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { CreateContact } from './create-contact.model';
import { ContactProxyService } from './contact-proxy.service';
import { of, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ContactCrudService {

    constructor(private contactProxyService: ContactProxyService,
        private notificationService: NotificationService) { }

    create(model: CreateEntity<CreateContact>): Observable<null | string> {
        model.id = uuid();
        return this.contactProxyService.create(model).pipe(
            map(() => {
                this.notificationService.success(`CONTACT.create:success`);
                return model.id;
            }),
            catchError(error => {
                this.notificationService.error(error, `CONTACT.create:errorMessage`);
                return of(null);
            })
        );
    }

    delete(id: string): Observable<boolean> {
        return this.contactProxyService.delete(id).pipe(
            map(() => {
                this.notificationService.success(`CONTACT.delete:success`);
                return true;
            }),
            catchError(error => {
                this.notificationService.error(error, `CONTACT.delete:errorMessage`);
                return of(false);
            })
        );
    }

    get(id: string): Observable<CreateContact> {
        return this.contactProxyService.get(id).pipe(
            map((x => {
                return x[0];
            }))
        );
    }

    update(id: string, model: CreateContact) {
        return this.contactProxyService.update(id, model).pipe(
            map(() => {
                this.notificationService.success(`CONTACT.update:success`);
                return true;
            }),
            catchError(error => {
                this.notificationService.error(error, `CONTACT.update:errorMessage`);
                return of(false);
            })
        );
    }
}
