import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { ApiListResponse, RequestList, NotificationService } from 'farmcloud-core';
import { ContactCoreServicesModule } from '../contact-core-services.module';
import { ContactCoreProxyService } from './contact-core-proxy.service';
import { IContactListFilter } from '../models/contact-list-filter';
import { IContactDto } from '../models/contact.dto';
import { IContactListItemDto } from '../models/contact-list-item.dto';
import { IUpdateContactCommand, ICreateContactCommand } from '../models/contact-command';

@Injectable({
    providedIn: ContactCoreServicesModule
})
export class ContactCoreService {

    constructor(
        protected contactCoreProxyService: ContactCoreProxyService,
        private notificationService: NotificationService) {
    }

    find(requestList: RequestList<IContactListFilter>): Observable<ApiListResponse<IContactListItemDto>> {
        return this.contactCoreProxyService.find(requestList);
    }

    get(id: string): Observable<IContactDto> {
        return this.contactCoreProxyService.get(id);
    }

    create(contactCommand: ICreateContactCommand): Observable<string> {
        contactCommand.id = uuid();

        return this.contactCoreProxyService.create(contactCommand).pipe(
            tap(() => this.notificationService.success('CONTACT.SERVICE.create:success')),
            catchError(error => {
                this.notificationService.error(error, 'CONTACT.SERVICE.create:errorMessage');
                return of(null);
            })
        );
    }

    update(id: string, contactCommand: IUpdateContactCommand): Observable<boolean> {
        return this.contactCoreProxyService.update(id, contactCommand).pipe(
            tap(() => this.notificationService.success(`CONTACT.SERVICE.update:success`)),
            map(() => (true)),
            catchError(error => {
                this.notificationService.error(error, `CONTACT.SERVICE.update:errorMessage`);
                throw of(false);
            })
        );
    }

    assignToOrganization(id: string, organizationId: string): Observable<boolean> {
        return this.contactCoreProxyService.assignToOrganization(id, organizationId).pipe(
            tap(() => this.notificationService.success(`CONTACT.SERVICE.assignToOrganization:success`)),
            map(() => (true)),
            catchError(error => {
                this.notificationService.error(error, `CONTACT.SERVICE.assignToOrganization:errorMessage`);
                throw of(false);
            })
        );
    }

    delete(id: string): Observable<boolean> {
        return this.contactCoreProxyService.delete(id).pipe(
            tap(() => this.notificationService.success(`CONTACT.SERVICE.delete:success`)),
            map(() => (true)),
            catchError(error => {
                this.notificationService.error(error, `CONTACT.SERVICE.delete:errorMessage`);
                return of(false);
            })
        );
    }
}

