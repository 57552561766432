import { Injectable } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';
import { ApiListResponse, RequestList } from 'farmcloud-core';
import { VehTelProxyService } from './vehtel-proxy.service';
import { VehTelDeviceListFilter } from './vehtel-device-list-filter.model';
import { VehTelDeviceListItem } from './vehtel-device-list-item.model';
import { map } from 'rxjs/operators';
import { VehTelListMachineItem } from './vehtel-list-machine-item.model';
import { OrganizationListService } from '@crm-portal/modules/organization/common/services/organization-list.service';
import { OrganizationListFilter } from '@crm-portal/modules/organization/common/models/organization-list-filter.model';
import { VehTelTagListItem } from './vehtel-tag-list-item.model';
import { VehTelTagListFilter } from './vehtel-tag-list-filter.model';
import { VehTelDevicePosition } from './vehtel-device-position.model';
import { VehTelDeviceSession } from './vehtel-device-session.model';
import { VehTelDeviceStatus } from './vehtel-device-status.model';
import { VehTelBeaconListItem } from '@crm-portal/core/vehtel/vehtel-beacon-list-item.model';
import { VehTelBeaconListFilter } from '@crm-portal/core/vehtel/vehtel-beacon-list-filter.model';
import { VehtelBeaconType } from '@crm-portal/core/vehtel/vehtel-beacon-type.model';

@Injectable({
  providedIn: 'root'
})
export class VehTelListService {

    constructor(private vehTelProxyService: VehTelProxyService,
      private organizationListService: OrganizationListService,) { }

    getFullDeviceList(requestList: RequestList<VehTelDeviceListFilter>): Observable<ApiListResponse<VehTelDeviceListItem>> {
      return forkJoin({
        machines: this.getMachines(true),
        devices: this.vehTelProxyService.getDeviceList(requestList)
      }).pipe(map(x => {
        for(const item of x.devices.items){
          item.machine = x.machines.find(machine => machine.id === item.vehicleRefId)
        }
        return {
          items: x.devices.items,
          pager: x.devices.pager
        }
      }));
    }

    getFullTagList(requestList: RequestList<VehTelTagListFilter>): Observable<ApiListResponse<VehTelTagListItem>> {
        return forkJoin({
          machines: this.getMachines(false),
          devices: this.vehTelProxyService.getTagList(requestList)
        }).pipe(map(x => {
          for(const item of x.devices.items){
            item.machine = x.machines.find(machine => machine.id === item.machineRefId)
          }
          return {
            items: x.devices.items,
            pager: x.devices.pager
          }
        }));
      }

    getFullBeaconList(requestList: RequestList<VehTelBeaconListFilter>): Observable<ApiListResponse<VehTelBeaconListItem>> {
        return forkJoin({
            machines: this.getMachines(false),
            devices: this.vehTelProxyService.getBeaconList(requestList)
            // tslint:disable-next-line:no-identical-functions
        }).pipe(map(x => {
            for(const item of x.devices.items){
                item.machine = x.machines.find(machine => machine.id === item.machineRefId)
            }
            return {
                items: x.devices.items,
                pager: x.devices.pager
            }
        }));
    }

    getDeviceList(requestList: RequestList<VehTelDeviceListFilter>): Observable<ApiListResponse<VehTelDeviceListItem>> {
      return this.vehTelProxyService.getDeviceList(requestList).pipe(map(x => {
        return {
          items: x.items,
          pager: x.pager
        }
      }));
    }

    getTagList(requestList: RequestList<VehTelTagListFilter>): Observable<ApiListResponse<VehTelTagListItem>> {
        // tslint:disable-next-line: no-identical-functions
        return this.vehTelProxyService.getTagList(requestList).pipe(map(x => {
          return {
            items: x.items,
            pager: x.pager
          }
        }));
      }

    getMachines(trackable): Observable<VehTelListMachineItem[]> {
      const organizationRequestList: RequestList<OrganizationListFilter> = {
        pageSize: 9999999,
        orderBy: 'id',
        sortOrder: 'asc',
        page: 0,
        filter: null
      }
      return forkJoin({
        machines: this.vehTelProxyService.getMachines(trackable).pipe(map(d => d.items)),
        organizations: this.organizationListService.getList(organizationRequestList).pipe(map(d => d.items))
      }).pipe(map(x => {
        for(const item of x.machines){
          item.organization = x.organizations.find(organization => organization.id === item.organizationId);
          if (item.organization) {
              item.organizationName = item.organization.name;
          }
        }
        return x.machines;
      }));
    }

    // tslint:disable-next-line: cognitive-complexity
    getEmptyMachines(trackable: boolean = true, currentMachine: string = ''): Observable<VehTelListMachineItem[]> {
      const requestList: RequestList<VehTelDeviceListFilter> = {
        pageSize: 9999999,
        orderBy: 'id',
        sortOrder: 'asc',
        page: 0,
        filter: null
      }
      const tagRequestList: RequestList<VehTelTagListFilter> = {
        pageSize: 9999999,
        orderBy: 'id',
        sortOrder: 'asc',
        page: 0,
        filter: null
      }
      return forkJoin({
        devices: this.getDeviceList(requestList),
        tags: this.getTagList(tagRequestList),
        machines: this.getMachines(trackable)
      }).pipe(map(x => {
        const result: VehTelListMachineItem[] = [];
          result.push({
          id: '',
          name: '--------',
          organization: {
              name: '--------',
              id: '',
              hasFarmPortalAccount: false,
              postCode: '',
              city: ''
          },
          organizationId: '',
          type: ''
      })
        for(const machine of x.machines){
            machine.name = `${machine.name} (${machine.type})`
          let add = true;
          for(const device of x.devices.items){
            if(device.vehicleRefId === machine.id){
              add = false;
            }
          }
          for(const tag of x.tags.items){
              if(tag.machineRefId === machine.id){
                  add = false;
              }
          }
          if(add || machine.id === currentMachine){
            result.push(machine);
          }
        }
        return result;
      }))
    }

    getDeviceTypes(): Observable<string[]> {
        return this.vehTelProxyService.getDeviceTypes();
    }

    getExtraDevices(): Observable<string[]> {
        return this.vehTelProxyService.getExtraDevices();
    }

    getTagTypes(): Observable<string[]> {
        return this.vehTelProxyService.getTagTypes();
    }

    getBeaconTypes(): Observable<VehtelBeaconType[]> {
        return this.vehTelProxyService.getBeaconTypes();
    }

    getVehiclePosition(vehRefId): Observable<VehTelDevicePosition> {
        return this.vehTelProxyService.getVehiclePosition(vehRefId);
    }

    getVehicleStatus(vehRefId): Observable<VehTelDeviceStatus> {
        return this.vehTelProxyService.getVehicleStatus(vehRefId);
    }

    getVehicleSessions(vehRefId): Observable<VehTelDeviceSession[]> {
        return this.vehTelProxyService.getVehicleSessions(vehRefId)
    }
}
