import { Component, OnInit } from '@angular/core';
import { TranslateService, LoggerService } from 'farmcloud-core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    constructor(private translateService: TranslateService,
        private loggerService: LoggerService) { }

    ngOnInit(): void {
        this.translateService.initialize();
        this.loggerService.initialize();
    }
}
