import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
    ApiListResponse,
    AppConfigurationService,
    CreateEntity,
    prepareListUrlWithFilter,
    RequestList
    } from 'farmcloud-core';
import { Observable } from 'rxjs';
import { VehTelDeviceListFilter } from './vehtel-device-list-filter.model';
import { VehTelDeviceListItem } from './vehtel-device-list-item.model';
import { CreateVehTelDevice } from './create-vehtel-device.model';
import { VehtelBeaconType, VehTelListMachineItem } from '.';
import { VehTelTagListFilter } from './vehtel-tag-list-filter.model';
import { VehTelTagListItem } from './vehtel-tag-list-item.model';
import { CreateVehTelTag } from './create-vehtel-tag.model';
import { VehTelDevicePosition } from './vehtel-device-position.model';
import { VehTelDeviceSession } from './vehtel-device-session.model';
import {AclPermissionsCommand} from 'farmcloud-shared';
import { VehTelDeviceStatus } from './vehtel-device-status.model';
import { VehTelBeaconListFilter } from './vehtel-beacon-list-filter.model';
import { VehTelBeaconListItem } from './vehtel-beacon-list-item.model';
import { CreateVehTelBeacon } from './create-vehtel-beacon.model';

@Injectable({
    providedIn: 'root'
})
export class VehTelProxyService {

    private readonly apiUrl: string;

    constructor(private httpClient: HttpClient, private config: AppConfigurationService) {
        this.apiUrl = `${this.config.configuration.api_url}/v1`;
    }

    getDeviceList(requestList: RequestList<VehTelDeviceListFilter>): Observable<ApiListResponse<VehTelDeviceListItem>> {
        return this.httpClient.get<ApiListResponse<VehTelDeviceListItem>>(
            prepareListUrlWithFilter(`${this.apiUrl}/devices`, requestList, this.prepareDeviceFilterUrl));
    }

    getTagList(requestList: RequestList<VehTelTagListFilter>): Observable<ApiListResponse<VehTelTagListItem>> {
        return this.httpClient.get<ApiListResponse<VehTelTagListItem>>(
            prepareListUrlWithFilter(`${this.apiUrl}/tags`, requestList, this.prepareTagFilterUrl));
    }

    getBeaconList(requestList: RequestList<VehTelBeaconListFilter>): Observable<ApiListResponse<VehTelBeaconListItem>> {
        return this.httpClient.get<ApiListResponse<VehTelBeaconListItem>>(
            prepareListUrlWithFilter(`${this.apiUrl}/beacons`, requestList, this.prepareTagFilterUrl));
    }

    getMachines(trackable: boolean = true): Observable<ApiListResponse<VehTelListMachineItem>> {
        return this.httpClient.get<ApiListResponse<VehTelListMachineItem>>(
            `${this.config.configuration.api_url}/v1/organizations/machines?trackable=${trackable}`);
    }

    createDevice(model: CreateEntity<CreateVehTelDevice>): Observable<string> {
        return this.httpClient.post<string>(`${this.apiUrl}/devices`, model);
    }

    createTag(model: CreateEntity<CreateVehTelTag>): Observable<string> {
        return this.httpClient.post<string>(`${this.apiUrl}/tags`, model);
    }

    createBeacon(model: CreateEntity<CreateVehTelBeacon>): Observable<string> {
        return this.httpClient.post<string>(`${this.apiUrl}/beacons`, model);
    }

    getDevice(imei: number): Observable<CreateVehTelDevice> {
        return this.httpClient.get<CreateVehTelDevice>(`${this.apiUrl}/devices/${imei}`);
    }

    getTag(serialNumber: number): Observable<CreateVehTelTag> {
        return this.httpClient.get<CreateVehTelTag>(`${this.apiUrl}/tags/${serialNumber}`);
    }

    getBeacon(serialNumber: number): Observable<CreateVehTelBeacon> {
        return this.httpClient.get<CreateVehTelBeacon>(`${this.apiUrl}/beacons/${serialNumber}`);
    }

    updateDevice(imei: number, model: CreateVehTelDevice): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/devices/${imei}`, model);
    }

    updateTag(serialNumber: number, model: CreateVehTelTag): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/tags/${serialNumber}`, model);
    }

    updateBeacon(serialNumber: number, model: CreateVehTelBeacon): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/beacons/${serialNumber}`, model);
    }

    getDeviceTypes(): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.apiUrl}/devices/dictionaries/types`);
    }

    getExtraDevices(): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.apiUrl}/devices/dictionaries/extra_devices`);
    }

    getTagTypes(): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.apiUrl}/tags/dictionaries/types`);
    }

    getBeaconTypes(): Observable<VehtelBeaconType[]> {
        return this.httpClient.get<VehtelBeaconType[]>(`${this.apiUrl}/beacons/dictionaries/types`);
    }

    getVehiclePosition(vehRefId): Observable<VehTelDevicePosition> {
        return this.httpClient.get<VehTelDevicePosition>(`${this.apiUrl}/vehicle/${vehRefId}/position`);
    }

    getVehicleStatus(vehRefId): Observable<VehTelDeviceStatus> {
      return this.httpClient.get<VehTelDeviceStatus>(`${this.apiUrl}/vehicle/${vehRefId}/status`);
    }

    getVehicleSessions(vehRefId): Observable<VehTelDeviceSession[]> {
        return this.httpClient.get<VehTelDeviceSession[]>(`${this.apiUrl}/vehicle/${vehRefId}/session`)
    }

    deleteDevice(imei: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/devices/${imei}`);
    }

    deleteTag(serialNumber: number): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/tags/${serialNumber}`);
    }

    getPermissions(imeiOrSn: number, deviceType: string): Observable<AclPermissionsCommand[]> {
        return this.httpClient.get<AclPermissionsCommand[]>(`${this.apiUrl}/${deviceType}/${imeiOrSn}/permissions`);
    }

    updatePermissions(imeiOrSn: number, deviceType: string, data: AclPermissionsCommand[]): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/${deviceType}/${imeiOrSn}/permissions`, data);
    }

    private prepareDeviceFilterUrl(filter: VehTelDeviceListFilter) {
        const params: string[] = [];

        if (filter.imei != null && filter.imei !== '') {
            params.push(`imei=${filter.imei}`);
        }

        return params;
    }

    private prepareTagFilterUrl(filter: VehTelTagListFilter) {
        const params: string[] = [];

        if (filter.SerialNumber != null && filter.SerialNumber !== 0) {
            params.push(`SerialNumber=${filter.SerialNumber}`);
        }

        return params;
    }
}
