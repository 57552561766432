import { Injectable } from '@angular/core';
import { NotificationService, CreateEntity } from 'farmcloud-core';
import { VehTelProxyService } from './vehtel-proxy.service';
import { map, catchError } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { CreateVehTelDevice } from '.';
import { CreateVehTelTag } from './create-vehtel-tag.model';
import {AclPermissionsCommand} from 'farmcloud-shared';
import { CreateVehTelBeacon } from './create-vehtel-beacon.model';

@Injectable({
  providedIn: 'root'
})
export class VehTelCrudService {

  constructor(private vehTelProxyService: VehTelProxyService,
    private notificationService: NotificationService) { }

  createDevice(model: CreateEntity<CreateVehTelDevice>): Observable<null | string> {
    return this.vehTelProxyService.createDevice(model).pipe(
      map(() => {
        this.notificationService.success(`VEHTEL.create:success`);
        return model.imei;
      }),
      catchError(error => {
        this.notificationService.error(error, `VEHTEL.create:errorMessage`);
        return of(null);
      })
    );
  }

  getDevice(imei: number): Observable<CreateVehTelDevice> {
    return this.vehTelProxyService.getDevice(imei);
  }

  updateDevice(imei: number, model: CreateVehTelDevice) {
    return this.vehTelProxyService.updateDevice(imei, model).pipe(
      map(() => {
        this.notificationService.success(`VEHTEL.update:success`);
        return true;
      }),
      catchError(error => {
        this.notificationService.error(error, `VEHTEL.update:errorMessage`);
        return of(false);
      })
    );
  }

  createTag(model: CreateEntity<CreateVehTelTag>): Observable<null | string> {
    return this.vehTelProxyService.createTag(model).pipe(
      map(() => {
        this.notificationService.success(`VEHTEL.create:success`);
        return model.serialNumber;
      }),
      catchError(error => {
        this.notificationService.error(error, `VEHTEL.create:errorMessage`);
        return of(null);
      })
    );
  }

    createBeacon(model: CreateEntity<CreateVehTelBeacon>): Observable<null | string> {
        return this.vehTelProxyService.createBeacon(model).pipe(
            map(() => {
                this.notificationService.success(`VEHTEL.create:success`);
                return model.serialNumber;
            }),
            catchError(error => {
                this.notificationService.error(error, `VEHTEL.create:errorMessage`);
                return of(null);
            })
        );
    }

  getTag(serialNumber: number): Observable<CreateVehTelTag> {
    return this.vehTelProxyService.getTag(serialNumber);
  }

    getBeacon(serialNumber: number): Observable<CreateVehTelBeacon> {
        return this.vehTelProxyService.getBeacon(serialNumber);
    }

  updateTag(serialNumber: number, model: CreateVehTelTag) {
    return this.vehTelProxyService.updateTag(serialNumber, model).pipe(
      map(() => {
        this.notificationService.success(`VEHTEL.update:success`);
        return true;
      }),
      catchError(error => {
        this.notificationService.error(error, `VEHTEL.update:errorMessage`);
        return of(false);
      })
    );
  }

    updateBeacon(serialNumber: number, model: CreateVehTelBeacon) {
        return this.vehTelProxyService.updateBeacon(serialNumber, model).pipe(
            map(() => {
                this.notificationService.success(`VEHTEL.update:success`);
                return true;
            }),
            catchError(error => {
                this.notificationService.error(error, `VEHTEL.update:errorMessage`);
                return of(false);
            })
        );
    }

    deleteDevice(imei: number): Observable<void> {
        return this.vehTelProxyService.deleteDevice(imei);
    }

    deleteTag(serialNumber: number): Observable<void> {
        return this.vehTelProxyService.deleteTag(serialNumber);
    }

    getPermissions(imeiOrSn: number, deviceType: string): Observable<AclPermissionsCommand[]> {
      return this.vehTelProxyService.getPermissions(imeiOrSn, deviceType);
    }

    updatePermissions(imeiOrSn: number, deviceType: string, data): Observable<void> {
        return this.vehTelProxyService.updatePermissions(imeiOrSn, deviceType, data);
    }
}
