export * from './contact-core-services.module'
export * from './services/contact-core.service';
export * from './models/contact-command';
export * from './models/contact-list-filter';
export * from './models/contact-list-item.dto';
export * from './models/contact.dto';

// obsolete below
export * from './contact-crud.service';
export * from './contact-form.layout';
export * from './contact-form.model';
export * from './contact-list-filter.model';
export * from './contact-list-item.model';
export * from './create-contact.model';
export * from './contact-list.service';
export * from './contact-proxy.service';
