import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription, forkJoin } from 'rxjs';
import { MsalService } from '@azure/msal-angular';
import { RouteInfo } from 'apex-shared';
import { SpinnerService, FeatureService, AppConfigurationService, FeatureConfiguration } from 'farmcloud-core';
import { MESSAGE_UNREAD_SERVICE } from 'farmcloud-shared';
import { ROUTES } from '@crm-portal/app-routing.config';
import { MessageCoreNotificationService } from '@crm-portal/core/message';
import { UserRoleService } from '@crm-portal/core/auth/user-role.service';
import { UserRoleConfiguration } from '@crm-portal/core/auth/user-role.configuration';



@Component({
    selector: 'app-full-layout',
    templateUrl: './full-layout.component.html',
    styleUrls: ['./full-layout.component.scss'],
    providers: [
        {
            provide: MESSAGE_UNREAD_SERVICE, useClass: MessageCoreNotificationService
        }
    ]
})
export class FullLayoutComponent implements OnInit, OnDestroy {

    hideSidebar: boolean;
    iscollapsed = false;
    displaySpinner$;

    private loadConfigurationSubscription: Subscription;
    constructor(private spinnerService: SpinnerService,
        private msalService: MsalService,
        private appConfigurationService: AppConfigurationService,
        private featureService: FeatureService,
        private userRoleService: UserRoleService) {
    }

    public routes: RouteInfo[];
    public user: {
        name,
        userName
    };

    ngOnInit() {
        this.displaySpinner$ = this.spinnerService.display$;
        this.user = this.msalService.getAccount();

        this.loadConfigurationSubscription = forkJoin({
            features: this.featureService.loadFeatures(),
            roles: this.userRoleService.loadUserRoles()
        }).subscribe(config => {
            this.routes = this.recursiveRouteFilter(ROUTES, config.features, config.roles);
        });
    }

    ngOnDestroy(): void {
        if (this.loadConfigurationSubscription) {
            this.loadConfigurationSubscription.unsubscribe();
        }
    }

    toggleHideSidebar($event: boolean): void {
        setTimeout(() => {
            this.hideSidebar = $event;
        }, 0);
    }

    logout() {
        this.msalService.logout();
    }

    private recursiveRouteFilter(
        routes: RouteInfo[],
        featureConfig: FeatureConfiguration,
        userRoleConfig: UserRoleConfiguration): RouteInfo[] {
        const result = [];
        routes.forEach(route => {
            if (featureConfig.isEnabled(route.title) && this.hasUserRights(route, userRoleConfig)) {
                this.checkIfExternalAndUpdatePath(route);
                if (route.submenu && route.submenu.length) {
                    route.submenu = this.recursiveRouteFilter(route.submenu, featureConfig, userRoleConfig);
                }
                result.push(route);
            }
        })
        return result;
    }

    private checkIfExternalAndUpdatePath(route: RouteInfo) {
        if (route.isExternalLink && route.title === 'APPLICATION.MENU.supplierPortal') {
            route.path = this.appConfigurationService.configuration.supplier_portal_url;
        }
    }

    private hasUserRights(route: RouteInfo, userRoleConfig: UserRoleConfiguration): boolean {
        switch (route.title) {
            case 'APPLICATION.MENU.AUDITS.productCatalogAuditsForTechnologist':
                return userRoleConfig.isTechnologist(this.user.userName);
            case 'APPLICATION.MENU.AUDITS.productCatalogAuditsForSecurityOffice':
                return userRoleConfig.isInspector(this.user.userName) || userRoleConfig.isDirector(this.user.userName);
            default: return true;
        }
    }
}
