import { RouteInfo } from 'apex-shared';

// Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
    {
        path: '',
        title: 'APPLICATION.MENU.dashboard',
        icon: 'ft-monitor',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '',
        title: 'APPLICATION.MENU.supplierPortal',
        icon: 'ft-airplay',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: true,
        submenu: []
    },
    {
        path: '/suppliers',
        title: 'APPLICATION.MENU.suppliers',
        icon: 'ft-briefcase',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '',
        title: 'APPLICATION.MENU.contracts',
        icon: 'ft-clipboard',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/contracts',
                title: 'APPLICATION.MENU.CONTRACTS.agreements',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/contracts/reports/suppliers',
                title: 'APPLICATION.MENU.CONTRACTS.reportSuppliers',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/contracts/reports/product-catalogs',
                title: 'APPLICATION.MENU.CONTRACTS.reportProductCatalogs',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/contracts/reports/deliveries',
                title: 'APPLICATION.MENU.CONTRACTS.reportDeliveries',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/product-catalog-infos',
                title: 'APPLICATION.MENU.CONTRACTS.productCatalogInfos',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
        ]
    },
    {
        path: '',
        title: 'APPLICATION.MENU.deliveries',
        icon: 'ft-airplay',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/deliveries',
                title: 'APPLICATION.MENU.DELIVERIES.list',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/deliveries/reports/suppliers',
                title: 'APPLICATION.MENU.DELIVERIES.reportSuppliers',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/deliveries/reports/products',
                title: 'APPLICATION.MENU.DELIVERIES.reportProducts',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '/organizations',
        title: 'APPLICATION.MENU.ogranizations',
        icon: 'ft-home',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/contacts',
        title: 'APPLICATION.MENU.contacts',
        icon: 'ft-users',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/messages',
        title: 'APPLICATION.MENU.messages',
        icon: 'ft-mail',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/notes',
        title: 'APPLICATION.MENU.notes',
        icon: 'ft-file',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '',
        title: 'APPLICATION.MENU.audits',
        icon: 'ft-award',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/employee-tasks',
                title: 'APPLICATION.MENU.AUDITS.employeeTasks',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/farm-audits',
                title: 'APPLICATION.MENU.AUDITS.farmAudits',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/field-audits',
                title: 'APPLICATION.MENU.AUDITS.fieldAudits',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/product-catalog-audits',
                title: 'APPLICATION.MENU.AUDITS.productCatalogAuditsForTechnologist',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/product-catalog-audit-results',
                title: 'APPLICATION.MENU.AUDITS.productCatalogAuditsForSecurityOffice',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
        ]
    },
    {
        path: '/support',
        title: 'APPLICATION.MENU.support',
        icon: 'ft-pocket',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/products',
        title: 'APPLICATION.MENU.products',
        icon: 'ft-box',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/weatherstations',
        title: 'APPLICATION.MENU.weatherStations',
        icon: 'ft-sun',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/moisturesensors',
        title: 'APPLICATION.MENU.moistureSensors',
        icon: 'ft-cloud-drizzle',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '',
        title: 'APPLICATION.MENU.vehtel',
        icon: 'ft-navigation',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/vehtel/device',
                title: 'APPLICATION.MENU.VEHTEL.devices',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/vehtel/tag',
                title: 'APPLICATION.MENU.VEHTEL.tags',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/vehtel/beacon',
                title: 'APPLICATION.MENU.VEHTEL.tags',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
        ]
    },
    {
        path: '',
        title: 'APPLICATION.MENU.storageSensors',
        icon: 'ft-monitor',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/storagemainsensors',
                title: 'APPLICATION.MENU.STORAGESENSOR.mainSensors',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/storageprismsensors',
                title: 'APPLICATION.MENU.STORAGESENSOR.prismSensors',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '',
        title: 'APPLICATION.MENU.databases',
        icon: 'ft-list',
        class: 'has-sub',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: [
            {
                path: '/pesticides',
                title: 'APPLICATION.MENU.pesticides',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/seeds',
                title: 'APPLICATION.MENU.seeds',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/fertilizers',
                title: 'APPLICATION.MENU.fertilizers',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            },
            {
                path: '/catalog-definitions',
                title: 'APPLICATION.MENU.catalogDefinitions',
                icon: '',
                class: '',
                badge: '',
                badgeClass: '',
                isExternalLink: false,
                submenu: []
            }
        ]
    },
    {
        path: '/fertilizer-stock-needs',
        title: 'APPLICATION.MENU.fertilizerStockNeeds',
        icon: 'ft-layers',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/transport-calculations',
        title: 'APPLICATION.MENU.transportCalculations',
        icon: 'ft-inbox',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/product-dealers',
        title: 'APPLICATION.MENU.productDealers',
        icon: 'ft-shopping-cart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    },
    {
        path: '/product-advisors',
        title: 'APPLICATION.MENU.productAdvisors',
        icon: 'ft-shopping-cart',
        class: '',
        badge: '',
        badgeClass: '',
        isExternalLink: false,
        submenu: []
    }
];
