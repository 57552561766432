export class CreateMessageCommand {
    constructor(
        public recipientOrganizationId: string,
        public recipientOrganizationName: string,
        public recipients: CreateMessageRecipientCommand[],
        public title: string,
        public body: string) {
    }
}

export class CreateMessageRecipientCommand {
    constructor(
        public userId: string,
        public userName: string) {

    }
}
