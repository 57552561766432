import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserRoleConfiguration } from './user-role.configuration';

@Injectable({
    providedIn: 'root'
})
export class UserRoleService {
    public loadUserRoles(): Observable<UserRoleConfiguration> {
        return new Observable(observer => {
            fetch('assets/roles-config.json')
                .then(response => response.json())
                .then(data => {
                    observer.next(new UserRoleConfiguration(data));
                    observer.complete();
                })
                .catch(err => observer.error(err));
        });
    }
}
