import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { DeviceType, RequestList, SpinnerService } from 'farmcloud-core';
import { IOrganizationListItemDto, OrganizationCoreService } from '@crm-portal/core/organization';
import { VehTelDeviceListFilter, VehTelDeviceListItem, VehTelListService } from '@crm-portal/core/vehtel';
import { WeatherStationCoreService } from '@crm-portal/core/weather-station';
import { WeatherStation } from '../common/models/weather-station.model';

@Component({
    selector: 'app-device-dashboard',
    templateUrl: './device-dashboard.component.html',
    styleUrls: ['./device-dashboard.component.scss']
})
export class DeviceDashboardComponent implements OnInit {

    private elements: {
        organizationId: string,
        organization: IOrganizationListItemDto,
        weather: Array<WeatherStation>,
        vehTel: Array<VehTelDeviceListItem>
    }[] = [];

    constructor(
        private vehTelService: VehTelListService,
        private weatherSensorService: WeatherStationCoreService,
        private organizationCoreService: OrganizationCoreService,
        private spinnerService: SpinnerService
    ) {
    }

    ngOnInit() {
        this.spinnerService.display();
        forkJoin({
            vehTel: this.vehTelService.getFullDeviceList(this.getRequestList<VehTelDeviceListFilter>()),
            reHydro: this.weatherSensorService.getAll(DeviceType.MoistureSensor),
            metPro: this.weatherSensorService.getAll(DeviceType.WeatherStation),
            storageMainSensor: this.weatherSensorService.getAll(DeviceType.StorageMainSensor),
            storagePrismSensor: this.weatherSensorService.getAll(DeviceType.StoragePrismSensor),
            organizations: this.organizationCoreService.getAll()
        }).subscribe(res => {
            const metPro = res.metPro.map(x => (new WeatherStation(x, DeviceType.WeatherStation)));
            const reHydro = res.reHydro.map(x => (new WeatherStation(x, DeviceType.MoistureSensor)));
            const storageMainSensor = res.storageMainSensor.map(x => (new WeatherStation(x, DeviceType.StorageMainSensor)));
            const storagePrismSensor = res.storagePrismSensor.map(x => (new WeatherStation(x, DeviceType.StoragePrismSensor)));

            const items = [
                ...metPro,
                ...reHydro,
                ...storageMainSensor,
                ...storagePrismSensor
            ]

            const organizations = items
                .map(x => x.organizationId)
                .filter((elem, index, array) => array.indexOf(elem) === index);
            organizations.push(...res.vehTel.items.filter(x => x.machine)
                .map(x => x.machine.organizationId)
                .filter((elem, index, array) => array.indexOf(elem) === index)
                .filter(x => organizations.indexOf(x) === -1))
            organizations.forEach(organizationId => {
                this.elements.push({
                    organizationId,
                    organization: res.organizations.find(x => x.id === organizationId),
                    weather: items.filter(x => x.organizationId === organizationId),
                    vehTel: res.vehTel.items.filter(x => x.machine).filter(x => x.machine.organizationId === organizationId)
                })
            })
            this.loadVehTelOnline()
            this.spinnerService.hide();
        })
        setInterval(() => {
            this.updateDate();
            // tslint:disable-next-line:no-magic-numbers
        }, 600000);
    }

    getRequestList<T>() {
        return {
            pageSize: 9999999,
            orderBy: 'id',
            sortOrder: 'asc',
            page: 0,
            filter: null
        } as RequestList<T>
    }

    filteredElements(): { weather: WeatherStation[], vehTel: VehTelDeviceListItem[] } {
        const returnElements: { weather: WeatherStation[], vehTel: VehTelDeviceListItem[] } = { vehTel: [], weather: [] };
        this.elements.forEach(x => {
            returnElements.weather.push(...x.weather)
            returnElements.vehTel.push(...x.vehTel)
        });

        return {
            weather: returnElements.weather.sort((a, b) => parseInt(a.serialNumber, 16) - parseInt(b.serialNumber, 16)),
            vehTel: returnElements.vehTel.sort((a, b) => a.imei - b.imei)
        };
    }

    getDeviceIcon(weather: WeatherStation): string {
        switch (weather.deviceType) {
            case DeviceType.MoistureSensor:
                return 'ft-droplet'
            case DeviceType.WeatherStation:
                return 'ft-sun'
            case DeviceType.StorageMainSensor:
                return 'ft-thermometer'
            default:
                return 'ft-layers'
        }
    }

    loadVehTelOnline() {
        this.elements.forEach(element => {
            element.vehTel.forEach(machine => {
                this.vehTelService.getVehiclePosition(machine.vehicleRefId).subscribe(res => {
                    // tslint:disable-next-line:no-magic-numbers
                    machine.lastOnline = res.timestamp * 1000;
                    machine.lastPosition = res.gps;
                })
            })
        })
    }

    getDeviceStatus(date: Date | number | string) {
        if (typeof date === 'number' || typeof date === 'string') {
            date = new Date(date);
        }
        // tslint:disable-next-line:no-magic-numbers
        const last3Hours = new Date(new Date().getTime() - (3 * 3600000));
        // tslint:disable-next-line:no-magic-numbers
        const before3Days = new Date(new Date().getTime() - (3 * 24 * 3600000));
        if (date > last3Hours) {
            return 'device--success';
        } else if (date > before3Days) {
            return 'device--warning'
        } else {
            return 'device--danger';
        }
    }

    updateDate() {
        this.loadVehTelOnline();
        forkJoin({
            reHydro: this.weatherSensorService.getAll(DeviceType.MoistureSensor),
            metPro: this.weatherSensorService.getAll(DeviceType.WeatherStation),
            storageMainSensor: this.weatherSensorService.getAll(DeviceType.StorageMainSensor),
            storagePrismSensor: this.weatherSensorService.getAll(DeviceType.StoragePrismSensor)
        }).subscribe(res => {
            const items = [
                ...res.metPro,
                ...res.reHydro,
                ...res.storageMainSensor,
                ...res.storagePrismSensor
            ]
            this.elements.forEach(element => {
                element.weather.forEach(weather => {
                    const weatherFromRequest = items.find(x => x.id === weather.id);
                    if (weatherFromRequest) {
                        weather.lastActiveDate = weatherFromRequest.lastActiveDate;
                    }
                })
            })
        })
    }
}
