import { RouterModule, PreloadAllModules } from '@angular/router';
import { Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { DashboardComponent } from '@crm-portal/modules/dashboard';
import { FullLayoutComponent } from '@crm-portal/core/layout';
import { MsalGuard } from '@azure/msal-angular';
import { DeviceType, ProductCatalogAuditViewType } from 'farmcloud-core';
import { UserGuard } from './core/auth/user.guard';
import { LoadUserDataComponent } from './core/layout/load-user-data/load-user-data.component';

const appRoutes: Routes = [
    {
        path: 'load-user-data',
        component: LoadUserDataComponent,
        canActivate: [MsalGuard]
    },
    {
        path: '',
        component: FullLayoutComponent,
        children: [
            {
                path: '',
                component: DashboardComponent
            },
            {
                path: 'dashboard',
                component: DashboardComponent
            },
            {
                path: 'suppliers',
                loadChildren: './modules/supplier/supplier.module#SupplierModule'
            },
            {
                path: 'contracts',
                loadChildren: './modules/contract/contract.module#ContractModule'
            },
            {
                path: 'deliveries',
                loadChildren: './modules/delivery/delivery.module#DeliveryModule'
            },
            {
                path: 'organizations',
                loadChildren: './modules/organization/organization.module#OrganizationModule'
            },
            {
                path: 'contacts',
                loadChildren: './modules/contact/contact.module#ContactModule'
            },
            {
                path: 'product-catalog-infos',
                loadChildren: './modules/product-catalog-info/product-catalog-info.module#ProductCatalogInfoModule',
                data: { viewType: ProductCatalogAuditViewType.Technologist }
            },
            {
                path: 'employee-tasks',
                loadChildren: './modules/employee-task/employee-task.module#EmployeeTaskModule'
            },
            {
                path: 'farm-audits',
                loadChildren: './modules/farm-audit/farm-audit.module#FarmAuditModule'
            },
            {
                path: 'field-audits',
                loadChildren: './modules/field-audit/field-audit.module#FieldAuditModule'
            },
            {
                path: 'product-catalog-audits',
                loadChildren: './modules/product-catalog-audit/product-catalog-audit.module#ProductCatalogAuditModule',
                data: { viewType: ProductCatalogAuditViewType.Technologist }
            },
            {
                path: 'product-catalog-audit-results',
                loadChildren: './modules/product-catalog-audit/product-catalog-audit.module#ProductCatalogAuditModule',
                data: { viewType: ProductCatalogAuditViewType.SecurityOffice }
            },
            {
                path: 'weatherstations',
                loadChildren: './modules/weather-station/weather-station.module#WeatherStationModule',
                data: { deviceType: DeviceType.WeatherStation }
            },
            {
                path: 'moisturesensors',
                loadChildren: './modules/weather-station/weather-station.module#WeatherStationModule',
                data: { deviceType: DeviceType.MoistureSensor }
            },
            {
                path: 'messages',
                loadChildren: './modules/message/message.module#MessageModule'
            },
            {
                path: 'notes',
                loadChildren: './modules/note/note.module#NoteModule'
            },
            {
                path: 'vehtel',
                loadChildren: './modules/vehtel/vehtel.module#VehTelModule'
            },
            {
                path: 'support',
                loadChildren: './modules/support/support.module#SupportModule'
            },
            {
                path: 'pesticides',
                loadChildren: './modules/pesticide/pesticide.module#PesticideModule'
            },
            {
                path: 'seeds',
                loadChildren: './modules/seed/seed.module#SeedModule'
            },
            {
                path: 'fertilizers',
                loadChildren: './modules/fertilizer/fertilizer.module#FertilizerModule'
            },
            {
                path: 'catalog-definitions',
                loadChildren: './modules/catalog-definition/catalog-definition.module#CatalogDefinitionModule'
            },
            {
                path: 'products',
                loadChildren: './modules/product/product.module#ProductModule'
            },
            {
                path: 'storagemainsensors',
                loadChildren: './modules/storage-sensor/storage-sensor.module#StorageSensorModule',
                data: { deviceType: DeviceType.StorageMainSensor }
            },
            {
                path: 'storageprismsensors',
                loadChildren: './modules/storage-sensor/storage-sensor.module#StorageSensorModule',
                data: { deviceType: DeviceType.StoragePrismSensor }
            },
            {
                path: 'fertilizer-stock-needs',
                loadChildren: './modules/fertilizer-stock-needs/fertilizer-stock-needs.module#FertilizerStockNeedsModule',
            },
            {
                path: 'transport-calculations',
                loadChildren: './modules/transport-calculation/transport-calculation.module#TransportCalculationModule',
            },
            {
                path: 'product-dealers',
                loadChildren: './modules/product-dealer/product-dealer.module#ProductDealerModule'
            },
            {
                path: 'product-advisors',
                loadChildren: './modules/product-advisor/product-advisor.module#ProductAdvisorModule'
            },
            {
                path: 'migration',
                loadChildren: './modules/migration/migration.module#MigrationModule'
            },
            {
                path: '**',
                redirectTo: 'dashboard'
            }
        ],
        canActivate: [MsalGuard, UserGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
