import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService } from 'farmcloud-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private httpClient: HttpClient,
        private config: AppConfigurationService) {
    }

    getUserStatus(tenantId: string): Observable<string> {
        const url = `${this.config.configuration.api_url}/v1/organizations/${tenantId}/access`;
        return this.httpClient.get<string>(url).pipe(map(access => {
            const token = JSON.stringify(access);
            return btoa(unescape(encodeURIComponent(token)));
        }));
    }
}
