import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeviceType } from 'farmcloud-core';
import { WeatherStationCoreServicesModule } from '../weather-station-core-services.module';
import { WeatherStationCoreProxyService } from './weather-station-core-proxy.service';
import { IWeatherStationPermissionDto } from '../models/weather-station-permission.dto';
import { IUpdateWeatherStationPermissionCommand } from '../models/weather-station-permission-command';

@Injectable({
    providedIn: WeatherStationCoreServicesModule
})
export class WeatherStationPermissionCoreService {

    constructor(private weatherStationCoreProxyService: WeatherStationCoreProxyService) { }

    getPermissions(deviceType: DeviceType, id: string): Observable<IWeatherStationPermissionDto[]> {
        return this.weatherStationCoreProxyService.getPermissions(deviceType, id);
    }

    updatePermissions(deviceType: DeviceType, id: string, data: IUpdateWeatherStationPermissionCommand[]): Observable<boolean> {
        return this.weatherStationCoreProxyService.updatePermissions(deviceType, id, data)
            .pipe(map(_ => true));
    }
}
