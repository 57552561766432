import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConfigurationService, prepareListUrlWithFilter } from 'farmcloud-core';
import { Observable } from 'rxjs';
import { ApiListResponse, prepareListUrl, RequestList } from 'farmcloud-core';
import { OrganizationListItem } from '../models/organization-list-item.model';
import { OrganizationListFilter } from '../models/organization-list-filter.model';
import { UpdateOrganization } from '../models/update-organization.model';
import { CreateOrganization } from '../models/create-organization.model';
import { ContactAssignmentListItem } from '../models/contact-assignment-list-item.model';
import {MachineListFilter, MachineListItem, ServiceCardEntry} from 'farmcloud-shared';
import { Machine } from 'projects/farmcloud-shared/src/lib/machine/common/models/machine';

@Injectable({
    providedIn: 'root'
})
export class OrganizationProxyService {

    private readonly apiUrl: string;

    constructor(private httpClient: HttpClient, private config: AppConfigurationService) {
        this.apiUrl = `${this.config.configuration.api_url}/v1/organizations`;
    }

    getList(requestList: RequestList<OrganizationListFilter>): Observable<ApiListResponse<OrganizationListItem>> {
        return this.httpClient
            .get<ApiListResponse<OrganizationListItem>>(prepareListUrlWithFilter(this.apiUrl, requestList, this.prepareFilterUrl));
    }

    getAssignedContactList(id: string): Observable<ContactAssignmentListItem[]> {
        return this.httpClient
            .get<ContactAssignmentListItem[]>(`${this.config.configuration.api_url}/v1/contacts/Organization/${id}?assignedOnly=true`);
    }

    getContactList(id: string, term: string): Observable<ContactAssignmentListItem[]> {
        return this.httpClient
            .get<ContactAssignmentListItem[]>(`${this.config.configuration.api_url}/v1/contacts/Organization/${id}/?term=${term}`);
    }

    assignToContact(id: string, contactId: string) : Observable<void> {
        return this.httpClient.put<void>(`${this.config.configuration.api_url}/v1/contacts/${contactId}/Organization/${id}`, {});
    }

    get(id: string): Observable<UpdateOrganization[]> {
        return this.httpClient.get<UpdateOrganization[]>(`${this.apiUrl}/${id}`);
    }

    delete(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${id}`);
    }

    update(id: string, model: UpdateOrganization): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/${id}`, model);
    }

    create(model: CreateOrganization): Observable<string> {
        return this.httpClient.post<string>(`${this.apiUrl}`, model);
    }

    getMachines(id: string, requestList: RequestList<MachineListFilter>): Observable<ApiListResponse<MachineListItem>> {
        return this.httpClient
            .get<ApiListResponse<MachineListItem>>
            (prepareListUrl(`${this.config.configuration.api_url}/v1/organizations/${id}/machines`, requestList));
    }

    getMachine(organizationId: string, id: string): Observable<Machine> {
        return this.httpClient
            .get<Machine>
            (`${this.config.configuration.api_url}/v1/organizations/${organizationId}/machines/${id}`);
    }

    updateMachine(organizationId: string, id: string, model: FormData): Observable<void> {
        return this.httpClient
            .put<void>
            (`${this.config.configuration.api_url}/v1/organizations/${organizationId}/machines/${id}`, model);
    }

    deleteMachine(organizationId: string, id: string): Observable<void> {
        return this.httpClient
            .delete<void>
            (`${this.config.configuration.api_url}/v1/organizations/${organizationId}/machines/${id}`);
    }

    createMachine(organizationId: string, model: FormData): Observable<Machine> {
        return this.httpClient
            .post<Machine>
            (`${this.config.configuration.api_url}/v1/organizations/${organizationId}/machines`, model);
    }

    getServiceEntries(organizationId: string, id: string): Observable<ServiceCardEntry[]> {
        return this.httpClient.get<ServiceCardEntry[]>(
            `${this.config.configuration.api_url}/v1/organizations/${organizationId}/machines/${id}/serviceCard`);
    }

    createServiceEntry(organizationId: string, id: string, data: ServiceCardEntry): Observable<void> {
        return this.httpClient.post<void>(
            `${this.config.configuration.api_url}/v1/organizations/${organizationId}/machines/${id}/serviceCard`, data);
    }

    private prepareFilterUrl(filter: OrganizationListFilter) {
        const params: string[] = [];
        if (filter.name != null && filter.name !== '') {
            params.push(`name=${filter.name}`);
        }

        if (filter.city != null && filter.city !== '') {
            params.push(`city=${filter.city}`);
        }

        return params;
    }
}
