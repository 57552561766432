import { isEmpty } from 'lodash-es';

export class UserRoleConfiguration {
    // tslint:disable-next-line:no-any
    private userRoleConfiguration: any = {};

    // tslint:disable-next-line: no-any
    constructor(userRoleConfiguration: any) {
        this.userRoleConfiguration = userRoleConfiguration;
    }

    public isTechnologist(email: string): boolean {
        return this.hasRole('technologist', email);
    }

    public isInspector(email: string): boolean {
        return this.hasRole('inspector', email);
    }

    public isDirector(email: string): boolean {
        return this.hasRole('director', email);
    }

    private hasRole(role: string, email: string): boolean {
        if (isEmpty(email)
            || this.userRoleConfiguration == null
            || isEmpty(this.userRoleConfiguration)) {
            return false;
        }

        return !isEmpty(this.userRoleConfiguration[role])
            && this.userRoleConfiguration[role].includes(email.toLowerCase());
    }
}
