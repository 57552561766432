import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConfigurationService, ApiListResponse, RequestList, prepareQueryParams } from 'farmcloud-core';
import { IContactListFilter } from '../models/contact-list-filter';
import { IContactDto } from '../models/contact.dto';
import { IContactListItemDto } from '../models/contact-list-item.dto';
import { ICreateContactCommand, IUpdateContactCommand } from '../models/contact-command';

@Injectable({
    providedIn: 'root'
})
export class ContactCoreProxyService {

    private readonly apiUrl: string;

    constructor(
        config: AppConfigurationService,
        private httpClient: HttpClient) {
        this.apiUrl = `${config.configuration.api_url}/v1/contacts`;
    }

    find(requestList: RequestList<IContactListFilter>): Observable<ApiListResponse<IContactListItemDto>> {
        const params = prepareQueryParams(new HttpParams(), requestList);
        return this.httpClient.get<ApiListResponse<IContactListItemDto>>(this.apiUrl, { params });
    }

    get(id: string): Observable<IContactDto> {
        return this.httpClient.get<IContactDto>(`${this.apiUrl}/${id}`);
    }

    create(createContractCommand: ICreateContactCommand): Observable<string> {
        return this.httpClient.post<string>(`${this.apiUrl}`, createContractCommand);
    }

    update(id: string, updateContractCommand: IUpdateContactCommand): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/${id}`, updateContractCommand);
    }

    assignToOrganization(id: string, organizationId: string): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/${id}/organization/${organizationId}`, {});
    }

    delete(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${id}`);
    }
}
