import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';

const TENANT_TOKEN_KEY = 'tid';

@Injectable({
    providedIn: 'root'
})
export class TenantService {

    constructor(private msalService: MsalService) { }

    get currentTenantId() {
        const account = this.msalService.getAccount();
        return account && account.idToken && account.idToken[TENANT_TOKEN_KEY];
    }
}
