import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigurationService, ApiListResponse, prepareQueryParams, RequestList } from 'farmcloud-core';
import { IOrganizationListFilter } from '../models/organization-list-filter';
import { IOrganizationListItemDto } from '../models/organization-list-item.dto';
import { IOrganizationSimpleListItemDto } from '../models/organization-simple-list-item.dto';
import { IOrganizationDto } from '../models/organization.dto';
import { ICreateOrganizationCommand, IUpdateOrganizationCommand } from '../models/organization-command';
import { IOrganizationSupplierDto } from '../models/organization-supplier.dto';

@Injectable({
    providedIn: 'root'
})
export class OrganizationCoreProxyService {

    private readonly apiUrl: string;

    constructor(private httpClient: HttpClient, private config: AppConfigurationService) {
        this.apiUrl = `${this.config.configuration.api_url}/v1/organizations`;
    }

    getAll(): Observable<IOrganizationListItemDto[]> {
        return this.httpClient.get<IOrganizationListItemDto[]>(`${this.apiUrl}/all`);
    }

    getSimpleListItemAll(): Observable<IOrganizationSimpleListItemDto[]> {
        return this.httpClient.get<IOrganizationSimpleListItemDto[]>(`${this.apiUrl}/list`);
    }

    find(requestList: RequestList<IOrganizationListFilter>): Observable<ApiListResponse<IOrganizationListItemDto>> {
        const params = prepareQueryParams(new HttpParams(), requestList);
        return this.httpClient.get<ApiListResponse<IOrganizationListItemDto>>(this.apiUrl, { params });
    }

    get(id: string): Observable<IOrganizationDto> {
        return this.httpClient.get<IOrganizationDto[]>(`${this.apiUrl}/${id}`)
            .pipe(map(x => x != null && x.length > 0 ? x[0] : null));
    }

    getOrganizationSuppliers(id: string): Observable<IOrganizationSupplierDto[]> {
        return this.httpClient.get<IOrganizationSupplierDto[]>(`${this.apiUrl}/${id}/suppliers`);
    }

    create(createContractCommand: ICreateOrganizationCommand): Observable<string> {
        return this.httpClient.post<string>(`${this.apiUrl}`, createContractCommand);
    }

    update(id: string, updateContractCommand: IUpdateOrganizationCommand): Observable<void> {
        return this.httpClient.put<void>(`${this.apiUrl}/${id}`, updateContractCommand);
    }

    delete(id: string): Observable<void> {
        return this.httpClient.delete<void>(`${this.apiUrl}/${id}`);
    }
}
